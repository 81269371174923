/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import { ChartGroup, Loading, ECHART_EVENT } from '@meteor/frontend-core';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import DataSwitcher from '../components/data-switcher';
import BufferSuitabilityEvaluationChart from '../components/kpi-chart/buffer-suitability-evaluation';
import EvmChart from '../components/kpi-chart/evm';
import EvmComparisonChart from '../components/kpi-chart/evm-comparison';
import PathSuitabilityEvaluationChart from '../components/kpi-chart/path-suitability-evaluation';
import StrategyMatrixCategoryChart from '../components/kpi-chart/strategy-matrix-category';
import StrategyMatrixDistributionChart from '../components/kpi-chart/strategy-matrix-distribution';
import TICRiskMatrixChart from '../components/kpi-chart/tic-risk-matrix';
import TimelineComparisonChart from '../components/kpi-chart/timeline-comparison';
import TimelineDisciplineChart from '../components/kpi-chart/timeline-discipline';
import WorkfrontManagement, { handleDataForXAxis } from '../components/kpi-chart/workfront-management';
import BasicInfoCard from '../components/basic-info-card';
import APIList from '../http/ApiList';
import { getDayString } from '../utils/DateUtil';
import { useTranslation } from 'react-i18next';
import { chain, concat, flatten, groupBy, keyBy, omit } from 'lodash';

const PlanOptionPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    dataSection,
    projectList,
    optimizeList,
    optimizationList,
    cwaIds,
    disciplineIds,
    selectOptions,
    sizeMode,
    menuCollapsed,
    scrollCount,
    setScrollCount,
    selectorTop,
  } = useContext(AppContext);

  const [chartLoading, setChartLoading] = useState(true);
  const [project, setProject] = useState<any>();
  const [optimizationData, setOptimizationData] = useState<any>();
  const [bufferSuitabilityEvaluationData, setBufferSuitabilityEvaluationData] = useState<any>([]);
  const [pathSuitabilityEvaluationData, setPathSuitabilityEvaluationData] = useState<any>([]);
  const [compareOptimizationData, setCompareOptimizationData] = useState<any>();
  const [kpiRoticData, setKpiRoticData] = useState<any>([]);
  const [strategyMatrixCategoryData, setStrategyMatrixCategoryData] = useState<any>([]);
  const [strategyMatrixDistributionData, setStrategyMatrixDistributionData] = useState<any>([]);
  const [workfrontManagementData, setWorkfrontManagementData] = useState<any>({});

  const [timelineDisciplineData, setTimelineDisciplineData] = useState<any>([]);
  const [timelineComparisonData, setTimelineComparisonData] = useState<any>([]);
  const [ticRiskMatrixData, setTICRiskMatrixData] = useState<any>([]);
  const [kpiRangeData, setKpiRangeData] = useState<any>([]);
  const [evmAxis, setEvmAxis] = useState<any>([]);
  const [timelineDisciplineAxis, setTimelineDisciplineAxis] = useState<any>([]);
  const [strategyMatrixDistributionAxis, setStrategyMatrixDistributionAxis] = useState<any>([]);
  const [bufferSuitabilityEvaluationAxis, setBufferSuitabilityEvaluationAxis] = useState<any>([]);
  const [pathSuitabilityEvaluationAxis, setPathSuitabilityEvaluationAxis] = useState<any>([]);
  const [workfrontManagementAxis, setWorkfrontManagementAxis] = useState<any>([]);

  const [filterValue, setFilterValue] = useState('0');
  const { globalEvent, setGlobalEvent } = useContext(AppContext);

  // for radio
  const [optimization, setOptimization] = useState();

  const [kpiRoticCompareLoading, setKpiRoticCompareLoading] = useState<any>(true);
  const [kpiRoticLoading, setKpiRoticLoading] = useState<any>(true);
  const [bufferSuitabilityEvaluationLoading, setBufferSuitabilityEvaluationLoading] = useState<any>(true);
  const [pathSuitabilityEvaluationLoading, setPathSuitabilityEvaluationLoading] = useState<any>(true);
  const [strategyMatrixCategoryLoading, setStrategyMatrixCategoryLoading] = useState<any>(true);
  const [strategyMatrixDistributionLoading, setStrategyMatrixDistributionLoading] = useState<any>(true);
  const [timelineDisciplineLoading, setTimelineDisciplineLoading] = useState<any>(true);
  const [timelineComparisonLoading, setTimelineComparisonLoading] = useState<any>([]);
  const [ticRiskMatrixLoading, setTICRiskMatrixLoading] = useState<any>([]);
  const [workfrontManagementLoading, setWorkfrontManagementLoading] = useState<any>(true);
  const [deadlines, setDeadlines] = useState<any>();
  const [deadline, setDeadline] = useState<any>();

  const chartHeight = 250;

  const onFilterData = () => {
    const { projectId, optimizationIds } = dataSection;
    const { projects, optimizations } = selectOptions;
    const projectInfo = projects.find((item) => projectId === item.projectId);
    const optimizationList = optimizations.filter((item) => optimizationIds.includes(item.optimizationId));
    setChartLoading(false);
    if (optimizationList.length === 0) {
      setKpiRoticData([]);
      roticFilterHandler([]);
      setBufferSuitabilityEvaluationData([]);
      setPathSuitabilityEvaluationData([]);
      setStrategyMatrixCategoryData([]);
      setStrategyMatrixDistributionData([]);
      setTimelineDisciplineData([]);
      setTimelineComparisonData([]);
      setTICRiskMatrixData([]);
      setKpiRoticLoading(false);
      setKpiRoticCompareLoading(false);
      setBufferSuitabilityEvaluationLoading(false);
      setPathSuitabilityEvaluationLoading(false);
      setStrategyMatrixCategoryLoading(false);
      setStrategyMatrixDistributionLoading(false);
      setTimelineDisciplineLoading(false);
      setTimelineComparisonLoading(false);
      setTICRiskMatrixLoading(false);
      setWorkfrontManagementLoading(false);
      setOptimizationData([]);
      return;
    }
    setOptimizationData(optimizationList);
    setProject(projectInfo);
    const { optimizeId, projectId: searchUseProjectId } = optimizationList[0];
    APIList.getKpiValueRange()
      .get({ optimizeId })
      .then((res: any) => {
        setKpiRangeData(res);
        getKpis(optimizationList.map((opt) => opt.optimizationId), searchUseProjectId, optimizeId);
      })
      .catch(() => {
        message.error('KPI Range Data Error');
      });
  };

  useEffect(() => {
    if (dataSection && selectOptions) {
      onFilterData();
    }
  }, [dataSection]);

  const getKpis = (optimizationIds, projectId, optimizeId) => {
    setKpiRoticCompareLoading(true);
    setKpiRoticLoading(true);
    APIList.getKpiRotic()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setKpiRoticData(res);
        setKpiRoticLoading(false);
        setKpiRoticCompareLoading(false);
        roticFilterHandler(res);
      });

    setBufferSuitabilityEvaluationLoading(true);
    setPathSuitabilityEvaluationLoading(true);
    setStrategyMatrixCategoryLoading(true);
    setStrategyMatrixDistributionLoading(true);
    setTimelineDisciplineLoading(true);
    setTimelineComparisonLoading(true);
    setTICRiskMatrixLoading(true);
    setWorkfrontManagementLoading(true);

    APIList.getBufferSuitabilityEvaluation()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setBufferSuitabilityEvaluationData(res);
        setBufferSuitabilityEvaluationLoading(false);
      })
      .catch(() => {
        message.error('Buffer Suitability Evaluation Data Error');
      });

    APIList.getPathSuitabilityEvaluation()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setPathSuitabilityEvaluationData(res);
        setPathSuitabilityEvaluationLoading(false);
      })
      .catch(() => {
        message.error('Path Suitability Evaluation Data Error');
      });

    APIList.getStrategyMatrixCategory()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setStrategyMatrixCategoryData(res);
        setStrategyMatrixCategoryLoading(false);
      })
      .catch(() => {
        message.error('Strategy Matrix Category Data Error');
      });

    APIList.getStrategyMatrixDistribution()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setStrategyMatrixDistributionData(res);
        setStrategyMatrixDistributionLoading(false);
      })
      .catch(() => {
        message.error('Strategy Matrix Distribution Data Error');
      });

    APIList.getTimelineDiscipline()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
      })
      .then((res: any) => {
        setTimelineDisciplineData(res);
        setTimelineDisciplineLoading(false);
      })
      .catch(() => {
        message.error('Timeline Discipline Data Error');
      });

    APIList.getTimelineComparison()
      .post({
        optimizationIds,
      })
      .then((res: any) => {
        setTimelineComparisonData(res);
        setTimelineComparisonLoading(false);
      })
      .catch(() => {
        message.error('Timeline Comparison Data Error');
      });

    APIList.getTICRiskMatrix()
      .post({
        optimizationIds,
      })
      .then((res: any) => {
        setTICRiskMatrixData(res);
        setTICRiskMatrixLoading(false);
      })
      .catch(() => {
        message.error('TIC Risk Matrix Data Error');
      });

    APIList.getWorkfrontManagement()
      .post({ projectId, optimizationIds, optimizeId })
      .then((res: any) => {
        const groupData = groupBy(res, 'optimizationId');
        const refreence = flatten(chain(groupData).omit(optimizationIds).values().value());
        const source = chain(groupData).pick(optimizationIds).mapValues((i) => concat(i, refreence)).value();
        setWorkfrontManagementData(source);
        setWorkfrontManagementAxis(handleDataForXAxis(res));
      })
      .catch(() => {
        message.error('Workfront Management Data Error');
      })
      .finally(() => {
        setWorkfrontManagementLoading(false);
      });
  };

  useEffect(() => {
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      globalEvent.eventType = null;
    }
  }, []);

  useEffect(() => {
    // Chart reset 事件
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      if (
        globalEvent.chartTitle.value === 'Buffer Suitability Evaluation' ||
        globalEvent.chartTitle.value === 'Path Suitability Evaluation' ||
        globalEvent.chartTitle.value === 'Strategy Matrix Distribution'
      ) {
        navigate(`/aipskd/plan-option-detail?optimizationId=${globalEvent.value[4]}`);
        setGlobalEvent(globalEvent);
      }
    }
  }, [globalEvent]);

  useEffect(() => {
    setDeadlines({
      feedStart: project?.feedStartDate ? getDayString(project?.feedStartDate) : undefined,
      epcStart: project?.epcStartDate ? getDayString(project?.epcStartDate) : undefined,
      projectStart: project?.startDate ? getDayString(project?.startDate) : undefined,
      projectEnd: project?.endDate ? getDayString(project?.endDate) : undefined,
    });
    setDeadline(project ? project.endDate : '2023-02-13');
  }, [project]);

  useEffect(() => {
    setEvmAxis(kpiRangeData.find((item) => item.kpiName === 'Evm'));
    setTimelineDisciplineAxis(kpiRangeData.find((item) => item.kpiName === 'TimelineDiscipline'));
    setStrategyMatrixDistributionAxis(kpiRangeData.find((item) => item.kpiName === 'StrategyMatrixDistribution'));
    setBufferSuitabilityEvaluationAxis(kpiRangeData.find((item) => item.kpiName === 'BufferSuitabilityEvaluation'));
    setPathSuitabilityEvaluationAxis(kpiRangeData.find((item) => item.kpiName === 'PathSuitabilityEvaluation'));
  }, [kpiRangeData]);

  const roticFilterHandler = (kpiRotic) => {
    setKpiRoticCompareLoading(true);
    const compareData = {
      costItems: {
        pvCompareData: [],
        evCompareData: [],
        acCompareData: [],
      },
      today: undefined,
      dueDate: undefined,
      deliveryDate: undefined,
    };
    kpiRotic.forEach((roticData) => {
      if (roticData.costItems.pvData && roticData.costItems.pvData.length > 0) {
        compareData.costItems.pvCompareData.push({
          name: roticData.optimizationName,
          data: roticData.costItems.pvData || [],
        });
        compareData.today = roticData.today;
        compareData.dueDate = roticData.dueDate;
        compareData.deliveryDate = roticData.deliveryDate;
      }
      if (roticData.costItems.evData && roticData.costItems.evData.length > 0) {
        compareData.costItems.evCompareData.push({
          name: roticData.optimizationName,
          data: roticData.costItems.evData || [],
        });
        compareData.today = roticData.today;
        compareData.dueDate = roticData.dueDate;
        compareData.deliveryDate = roticData.deliveryDate;
      }
      if (roticData.costItems.acData && roticData.costItems.acData.length > 0) {
        compareData.costItems.acCompareData.push({
          name: roticData.optimizationName,
          data: roticData.costItems.acData || [],
        });
        compareData.today = roticData.today;
        compareData.dueDate = roticData.dueDate;
        compareData.deliveryDate = roticData.deliveryDate;
      }
    });
    setCompareOptimizationData(compareData);
    setTimeout(() => {
      setKpiRoticCompareLoading(false);
    }, 200);
  };

  return (
    <>
      <DataSwitcher
        // title="Plan Option"
        title={t('aipskd.planOption.planOption')}
        readonly={false}
        optimizationSelected={optimization}
      >
        <div>
          <ChartGroup
            height={chartHeight}
            loading={false}
            defaultItemCount={3}
            isScrollChart
            isSmallMode
            sizeMode={sizeMode}
            menuCollapsed={menuCollapsed}
            scrollCount={scrollCount}
            setScrollCount={setScrollCount}
            selectorTop={selectorTop}
          >
            <ChartGroup.Item height={chartHeight}>
              <EvmComparisonChart data={compareOptimizationData} loading={kpiRoticCompareLoading} axis={evmAxis} />
            </ChartGroup.Item>
            <ChartGroup.Item>
              <TimelineComparisonChart loading={timelineComparisonLoading} data={timelineComparisonData} deadlines={deadlines} />
            </ChartGroup.Item>

            <ChartGroup.Item>
              <TICRiskMatrixChart loading={ticRiskMatrixLoading} data={ticRiskMatrixData} />
            </ChartGroup.Item>
          </ChartGroup>
          <div
            className="scroll-content custom-scrollbar"
            style={{
              height: 'calc(100vh - 438px)',
              overflowX: 'hidden',
            }}
          >
            {optimizationData ? (
              optimizationData
                .filter((item, index) => {
                  const selectIndex = parseInt(filterValue, 10) - 1;
                  return selectIndex < 0 || index === selectIndex;
                })
                .map((optData, index) => (
                  <div key={optData.optimizationId}>
                    <BasicInfoCard optimizationData={optData} radio={{ optimization, setOptimization }} />
                    <ChartGroup
                      key={optData.optimizationId}
                      height={chartHeight}
                      loading={chartLoading}
                      isScrollChart
                      isSmallMode
                      syncScroll
                      sizeMode={sizeMode}
                      menuCollapsed={menuCollapsed}
                      scrollCount={scrollCount}
                      setScrollCount={setScrollCount}
                      selectorTop={selectorTop}
                    >
                      <ChartGroup.Item>
                        <EvmChart data={kpiRoticData[index]} loading={kpiRoticLoading} axis={evmAxis} />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <TimelineDisciplineChart
                          loading={timelineDisciplineLoading}
                          data={timelineDisciplineData[index]}
                          deadline={deadline}
                          axis={timelineDisciplineAxis}
                        />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <StrategyMatrixCategoryChart loading={strategyMatrixCategoryLoading} data={strategyMatrixCategoryData[index]} />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <StrategyMatrixDistributionChart
                          loading={strategyMatrixDistributionLoading}
                          uuid={optData.optimizationId}
                          data={strategyMatrixDistributionData[index]}
                        />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <BufferSuitabilityEvaluationChart
                          loading={bufferSuitabilityEvaluationLoading}
                          data={bufferSuitabilityEvaluationData[index]}
                          axis={bufferSuitabilityEvaluationAxis}
                        />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <PathSuitabilityEvaluationChart
                          loading={pathSuitabilityEvaluationLoading}
                          data={pathSuitabilityEvaluationData[index]}
                          axis={pathSuitabilityEvaluationAxis}
                        />
                      </ChartGroup.Item>
                      <ChartGroup.Item>
                        <WorkfrontManagement
                          loading={workfrontManagementLoading}
                          data={workfrontManagementData[optData.optimizationId]}
                          axis={workfrontManagementAxis}
                        />
                      </ChartGroup.Item>
                    </ChartGroup>
                  </div>
                ))
            ) : (
              <Loading fixed={false} />
            )}
          </div>
        </div>
      </DataSwitcher>
    </>
  );
};

export default PlanOptionPage;
