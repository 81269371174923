import React, { forwardRef, memo, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react';
import { CheckColumn, Column, ColumnStore, DataField, DateHelper, Gantt, PresetManager, ProjectModel, Store } from '@bryntum/gantt';
import { isNil, orderBy, round, uniq, uniqBy } from 'lodash';
import AlertTaskContext from '../contexts/AlertTaskContext';
import { getRandomString } from '../utils/commonUtil';
import '@bryntum/gantt/gantt.stockholm.css';
import { App, Button } from 'antd';
import { getDayString } from '../utils/DateUtil';
import { Loading } from '@meteor/frontend-core';
import RelatedPathContext from '../contexts/RelatedPathContext';

PresetManager.registerPreset('monthAndYear', {
  base: 'monthAndYear',
  tickWidth: 70, // 设置宽度
  headers: [
    {
      unit: 'YEAR',
      align: 'center',
      dateFormat: 'Y',
    },
    {
      unit: 'MONTH',
      align: 'center',
      dateFormat: 'MMM',
    },
  ],
});

const ganttConfig: BryntumGanttProps = {
  selectionMode: {
    multiSelect: false,
    dragSelect: false,
  },
  // appendTo: 'main',
  // flex: '1 1 auto',
  scrollTaskIntoViewOnCellClick: true,
  subGridConfigs: {
    fixed: {
      // No resizing of the fixed column region
      resizable: false,
      // Set a lower weight than the built-in "locked" section to have it appear to the left
      weight: 1,
    },
    locked: {
      // A scrollable section with the main columns
      width: 320,
      weight: 2,
    },
  },
  columns: [
    { type: 'name', field: 'name', width: 320, editor: false },
    {
      text: 'Activity ID',
      field: 'taskId',
      width: 160,
      renderer: (task) => `${task.record.taskId || '-'}`,
      editor: false,
    },
    {
      text: 'wbs',
      field: 'wbs',
      renderer: (task) => `${task.record.wbs || '-'}`,
      editor: false,
    },
    {
      text: 'discipline',
      field: 'discipline',
      renderer: (task) => `${task.record.discipline || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    {
      text: 'CWA',
      field: 'area',
      renderer: (task) => `${task.record.area || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    {
      text: 'package',
      field: 'package',
      renderer: (task) => `${task.record.package || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    { type: 'startdate', editor: false },
    { type: 'enddate', editor: false },
    { type: 'duration', editor: false },
    { type: 'predecessor', editor: false },
  ],
  barMargin: 5,
  rowHeight: 20,
  cls: 'gantt-bar',
  project: {
    // startDate: '2023-03-01',
    // endDate: '2024-12-09',
    taskStore: {
      fields: [
        'name',
        'taskId',
        'wbs',
        'discipline',
        'area',
        'package',
        'startdate',
        'enddate',
        'duration',
        'predecessor',
        'fwbsLevel',
        'cstrDate',
        'mpPerDay',
      ],
    },
    // transport: {
    //   load: {
    //     // url: '/api/schedule-tasks',
    //     url: '../data/gantt-data.json',
    //   },
    // },
    // autoLoad: false,
    // The State TrackingManager which the UndoRedo widget in the toolbar uses
    stm: {
      autoRecord: true,
    },
    // Reset Undo / Redo after each load
    resetUndoRedoQueuesAfterLoad: true,
    calendarsData: [
      {
        id: 'general',
        name: 'General',
        intervals: [
          {
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false,
          },
        ],
      },
    ],
  },
  viewPreset: 'monthAndYear',
  dependenciesFeature: {
    allowCreate: false,
    drawOnScroll: false,
  },
  percentBarFeature: {
    allowResize: false,
  },
  taskEditFeature: false,
  taskMenuFeature: false,
  taskDragFeature: false,
  taskResizeFeature: false,
  taskDragCreateFeature: false,
  criticalPathsFeature: {
    disabled: true,
  },
  filterFeature: true,
  progressLineFeature: {
    disabled: true,
  },
  taskNonWorkingTimeFeature: true,
  baselinesFeature: {
    disabled: false,
    template: (item): string => {
      const { baseline } = item;
      const { task } = baseline;

      const displayDuration = baseline.duration;

      return `<div class="b-gantt-task-title">
              ${task.name} (${baseline.name})</div>
              <table>
                <tr>
                  <td>Start:</td>
                  <td>${item.startClockHtml}</td>
                </tr>
                <tr>
                  <td>End:</td>
                  <td>${item.endClockHtml}</td>
                </tr>
                <tr>
                  <td>Duration:</td>
                  <td class="b-right">${`${displayDuration} ${DateHelper.getLocalizedNameOfUnit(
                    baseline.durationUnit,
                    baseline.duration !== 1
                  )}`}</td>
                </tr>
              </table>
              `;
    },
  },
  timeRangesFeature: true,
  indicatorsFeature: {
    items: {
      earlyDates: false,
      lateDates: false,
      deadlineDate: false,
      constraintDate: false,
      cstrDateIndicator: (taskRecord) =>
        taskRecord.cstrDate
          ? {
              startDate: new Date(taskRecord.cstrDate),
              name: 'Constraint Date',
              iconCls: 'b-cus-diamond',
            }
          : null,
    },
    tooltipTemplate: (data) => {
      console.log(data);
      const {
        indicator: {
          taskRecord: { name, startDate, endDate, duration, percentDone },
        },
        startText,
      } = data;
      return `<div>
        <div style="font-weight: bolder">${name}</div>
        <div style="display: flex; justify-content: space-between;">
          <div>
            ${!isNil(startDate) ? `<div>Start: </div>` : ''} 
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>End: </div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>Duration: </div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>Complete: </div>` : ''}
            <div>Constraint: </div>
          </div>
          <div style="margin-left: 5px; text-align: right">
            ${!isNil(startDate) ? `<div>${DateHelper.format(startDate, 'MMM DD, YYYY')}</div>` : ''} 
            ${
              !isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${DateHelper.format(endDate, 'MMM DD, YYYY')}</div>` : ''
            } 
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${round(duration, 1)} days</div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${percentDone * 100}%</div>` : ''}
            <div>${startText}</div>
          </div>
        </div>
      </div>`;
    },
  },
};

const GanttSubChart: React.FC<any> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    scrollToTask,
  }));

  const { height, isReady } = props;
  const { selected, drivingOnly, setRelatedTasks } = useContext(RelatedPathContext);

  const gantt = useRef<any>(null);
  const [ganttKey, setGanttKey] = useState<string>();

  const [loading, setLoading] = useState(false);

  const ganttInstance = (): Gantt => gantt.current?.instance;
  const ganttProject = (): ProjectModel => ganttInstance().project;
  const ganttStore = (): Store => ganttInstance().store as Store;
  const ganttColumns = () => ganttInstance().columns as ColumnStore;

  const [ganttCardConfig] = useState({
    ...ganttConfig,
  });

  useEffect(() => {
    if (!selected) {
      return;
    }
    setGanttKey(getRandomString());
  }, [selected, drivingOnly]);

  const getSelectedGanttData = () => {
    const tasksOrgData = [];
    const taskOrgDeps = [];
    const getFromTasks = (task, fromTasks, fromDeps) => {
      fromTasks.unshift(task);
      const dependencies = task.dependencies;
      const fromTaskDeps = dependencies.filter(({ from, to, data }) => {
        if (drivingOnly) {
          return to === task.taskId && data.isDrivingPath;
        }
        return to === task.taskId;
      });
      fromTaskDeps.forEach((dep) => {
        const { id, fromTask } = dep;
        if (fromDeps.findIndex((dep) => dep.id === id) === -1) {
          fromDeps.push(dep);
        }
        if (fromTasks.findIndex((task) => task.id === fromTask.id) === -1) {
          getFromTasks(fromTask, fromTasks, fromDeps);
        }
      });
    };
    getFromTasks(selected, tasksOrgData, taskOrgDeps);
    const tasksData = tasksOrgData.map((data) => ({
      id: data.id,
      name: data.name,
      taskId: data.taskId,
      wbs: data.wbs,
      discipline: data.discipline,
      area: data.area,
      package: data.package,
      startDate: data.startDate,
      endDate: data.endDate,
      duration: data.duration,
      predecessor: data.predecessor,
      fwbsLevel: data.fwbsLevel,
      cstrDate: data.cstrDate,
      cls: data.cls,
      constraintDate: data.constraintDate,
      constraintType: data.constraintType,
      mpPerDay: data.mpPerDay,
    }));
    const dependencies = taskOrgDeps.map((dep) => ({
      id: dep.id,
      fromTask: dep.from,
      toTask: dep.to,
      type: dep.type,
      lag: dep.lag,
    }));
    return {
      dependenciesData: dependencies,
      tasksData: orderBy(tasksData, 'startDate'),
    };
  };

  const scrollToTask = (selected) => {
    try {
      ganttInstance()
        .scrollTaskIntoView(selected, {
          animate: true,
          block: 'center',
          highlight: true,
          focus: true,
        })
        .then(() => {
          ganttInstance().selectRow(selected);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!gantt.current || !ganttKey || !isReady) {
      return;
    }
    setLoading(true);
    const loadData = getSelectedGanttData();
    setRelatedTasks(loadData.tasksData);
    ganttProject()
      .loadInlineData(loadData)
      .then(() => {
        scrollToTask(selected);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ganttKey, drivingOnly, isReady]);

  return (
    <>
      <BryntumGantt key={ganttKey} ref={gantt} height={height} {...ganttCardConfig} />
      {loading && <Loading style={{ position: 'absolute', top: -150 }} />}
    </>
  );
});
export default GanttSubChart;
