import { END_POINT_NAME } from '../consts/endpoint';
import { apiCommon, API_MODE } from '@meteor/frontend-core';

const APIList = {
  getProjectPlans: apiCommon(END_POINT_NAME.EDGE_API, '/api/plans'),
  createProjectPlan: apiCommon(END_POINT_NAME.EDGE_API, '/api/create-plan'),
  updateProjectPlan: apiCommon(END_POINT_NAME.EDGE_API, '/api/update-plan/:planId'),
  deleteProjectPlan: apiCommon(END_POINT_NAME.EDGE_API, '/api/delete-plan/:id'),

  getProjects: apiCommon(END_POINT_NAME.EDGE_API, '/api/projects'),
  getInput: apiCommon(END_POINT_NAME.EDGE_API, '/api/get-input'),

  getTaskSchedule: apiCommon(END_POINT_NAME.EDGE_API, '/api/task-schedule/:optimizationId', API_MODE.LONG_RESPONSE),
  getMpPeak: apiCommon(END_POINT_NAME.EDGE_API, '/api/mp-peak/:optimizationId', API_MODE.LONG_RESPONSE),
  getOptimizes: apiCommon(END_POINT_NAME.EDGE_API, '/api/optimizes'),

  getOptimizations: apiCommon(END_POINT_NAME.EDGE_API, '/api/optimizations'),
  updateOptimizationMaster: apiCommon(END_POINT_NAME.EDGE_API, '/api/update-optimization-master/:optimizeId'),
  getKpis: apiCommon(END_POINT_NAME.EDGE_API, '/api/get-kpis'),
  getAlerts: apiCommon(END_POINT_NAME.EDGE_API, '/api/alerts/:optimizationId'),
  getKpiRotic: apiCommon(END_POINT_NAME.EDGE_API, '/api/kpi-totic'),
  getDisciplines: apiCommon(END_POINT_NAME.EDGE_API, '/api/disciplines'),
  getCwas: apiCommon(END_POINT_NAME.EDGE_API, '/api/cwas'),
  getBufferSuitabilityEvaluation: apiCommon(END_POINT_NAME.EDGE_API, '/api/buffer-suitability-evaluations'),
  getPathSuitabilityEvaluation: apiCommon(END_POINT_NAME.EDGE_API, '/api/path-suitability-evaluations'),
  getStrategyMatrixCategory: apiCommon(END_POINT_NAME.EDGE_API, '/api/strategy-matrix-categories'),
  getTimelineDiscipline: apiCommon(END_POINT_NAME.EDGE_API, '/api/timeline-disciplines'),
  getTimelineComparison: apiCommon(END_POINT_NAME.EDGE_API, '/api/timeline-comparisons'),
  getTICRiskMatrix: apiCommon(END_POINT_NAME.EDGE_API, '/api/tic-risk-matrixes'),
  getStrategyMatrixDistribution: apiCommon(END_POINT_NAME.EDGE_API, '/api/strategy-matrix-distributions', API_MODE.LONG_RESPONSE),
  wakeUpApi: apiCommon(END_POINT_NAME.EDGE_API, '/api/wake-up-api'),
  getKpiValueRange: apiCommon(END_POINT_NAME.EDGE_API, '/api/kpi-value-range'),
  getSystemRoles: apiCommon(END_POINT_NAME.EDGE_API, '/api/system-roles'),
  getProjectRoles: apiCommon(END_POINT_NAME.EDGE_API, '/api/project-roles'),
  getAuthentication: apiCommon(END_POINT_NAME.EDGE_API, '/api/authentication'),
  getAlertMemo: apiCommon(END_POINT_NAME.EDGE_API, '/api/alert-memo'),
  createAlertMemo: apiCommon(END_POINT_NAME.EDGE_API, '/api/create-alert-memo'),
  updateAlertMemo: apiCommon(END_POINT_NAME.EDGE_API, '/api/update-alert-memo'),
  deleteAlertMemo: apiCommon(END_POINT_NAME.EDGE_API, '/api/delete-alert-memo/:id'),
  getOptimizationJob: apiCommon(END_POINT_NAME.EDGE_API, '/api/optimization-job'),
  getStages: apiCommon(END_POINT_NAME.EDGE_API, '/api/get-stage'),
  getUsers: apiCommon(END_POINT_NAME.EDGE_API, '/api/get-users'),
  createUser: apiCommon(END_POINT_NAME.EDGE_API, '/api/create-user'),
  updateUser: apiCommon(END_POINT_NAME.EDGE_API, '/api/update-user/:userId'),
  deleteUser: apiCommon(END_POINT_NAME.EDGE_API, '/api/delete-user/:userId'),
  getMembers: apiCommon(END_POINT_NAME.EDGE_API, '/api/get-project-users'),
  createOrUpdateMemberRole: apiCommon(END_POINT_NAME.EDGE_API, '/api/create-project-user-role'),
  startOptimization: apiCommon(END_POINT_NAME.EDGE_API, '/api/start-optimization'),
  stopOptimization: apiCommon(END_POINT_NAME.EDGE_API, '/api/stop-optimization'),
  startOriginal: apiCommon(END_POINT_NAME.EDGE_API, '/api/start-original'),
  stopOriginal: apiCommon(END_POINT_NAME.EDGE_API, '/api/stop-original'),
  startDataLinkage: apiCommon(END_POINT_NAME.EDGE_API, '/api/start-data-linkage'),
  stopDataLinkage: apiCommon(END_POINT_NAME.EDGE_API, '/api/stop-data-linkage'),
  closeProject: apiCommon(END_POINT_NAME.WSS_END_POINT, '/api/close-project', API_MODE.WEBSOCKET),
  stopCloseProject: apiCommon(END_POINT_NAME.EDGE_API, '/api/stop-close-project'),
  getWorkfrontManagement: apiCommon(END_POINT_NAME.EDGE_API, '/api/workfront-management'),
  // ph2
  setActiveTask: apiCommon(END_POINT_NAME.EDGE_API, '/api/set-active-task'),

  getTaskSensitivity: apiCommon(END_POINT_NAME.EDGE_API, '/api/task-sensitivity'),
};

export default APIList;
