import { Loading, MeteorEchart } from '@meteor/frontend-core';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import RelatedPathContext from '../contexts/RelatedPathContext';
import { cloneDeep, groupBy } from 'lodash';
import APIList from '../http/ApiList';
import { getAllDay } from '../utils/DateUtil';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'; // ES 2015
dayjs.extend(isoWeek);

const PathMpPeak: React.FC<any> = (props, ref) => {
  const { echartTheme } = useContext(AppContext);
  const { drivingOnly, selected, relatedTasks } = useContext(RelatedPathContext);
  const { optimizeId, calenderData } = props;

  const [loading, setLoading] = useState(true);

  const instance = useRef(null);

  const [option, setOption] = useState({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendToBody: true,
      className: 'overflow-all',
      hideDelay: 0,
      order: 'seriesDesc',
    },
    legend: {},
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
      },
      z: 10,
      data: [],
    },
    yAxis: {
      name: 'MP',
      position: 'left',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: 50,
      top: 50,
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'inside',
      },
      {
        type: 'slider',
      },
    ],
    series: [],
  });

  const initChartData = () => {
    const calculateData = calculateDailyMPByDiscipline(relatedTasks);
    const aggregateData = aggregateWeeklyMP(calculateData);
    const gbDiscipline = groupBy(aggregateData, 'discipline');
    const gbDate = groupBy(aggregateData, 'date');
    const xAxisData = Object.keys(gbDate).sort();
    const series = Object.keys(gbDiscipline).map((discipline) => {
      const disciplineData = gbDiscipline[discipline];
      return {
        name: discipline,
        type: 'bar',
        stack: 'mp',
        data: xAxisData.map((xData) => {
          const findXData = disciplineData.find((item) => item.date === xData);
          return findXData?.value || 0;
        }),
      };
    });
    option.xAxis.data = xAxisData;
    option.series = series;
    console.log(option);
    setOption(cloneDeep(option));
  };

  const calculateDailyMPByDiscipline = (tasks) => {
    const mpPerDayPerDiscipline: Record<string, number> = {};
    tasks.forEach((task) => {
      let currentDate = dayjs(task.startDate);
      const endDate = dayjs(task.endDate);

      while (currentDate.isBefore(endDate, 'day') || currentDate.isSame(endDate, 'day')) {
        const dateString = currentDate.format('YYYY-MM-DD');
        const findData = calenderData.find((calender) => dayjs(calender.date).isSame(currentDate, 'day'));
        if (findData) {
          const key = `${dateString}/${task.discipline}`;
          if (!mpPerDayPerDiscipline[key]) {
            mpPerDayPerDiscipline[key] = 0;
          }

          mpPerDayPerDiscipline[key] += task.mpPerDay || 0;
        }
        // Move to the next day
        currentDate = currentDate.add(1, 'day');
      }
    });

    // Convert to flat array format
    const result: any[] = [];
    for (const key in mpPerDayPerDiscipline) {
      const [date, discipline] = key.split('/');
      result.push({
        date,
        discipline,
        value: mpPerDayPerDiscipline[key],
        unit: 'MP',
      });
    }

    return result;
  };

  const getSunday = (date: Dayjs): string => {
    return date.subtract(1, 'week').isoWeekday(1).format('YYYY-MM-DD');
  };

  const aggregateWeeklyMP = (data: { date: string; discipline: string; value: number; unit: string }[]) => {
    const groupedData: {
      [key: string]: {
        date: string;
        discipline: string;
        value: number;
        unit: string;
      };
    } = {};

    data.forEach((item) => {
      const sunday = getSunday(dayjs(item.date));
      const key = `${item.discipline}-${sunday}`;

      // Initialize the group if it doesn't exist
      if (!groupedData[key]) {
        groupedData[key] = { ...item, date: sunday };
      } else {
        // Update the value to the maximum for the group
        groupedData[key].value = Math.max(groupedData[key].value, item.value);
      }
    });

    return Object.values(groupedData);
  };

  useEffect(() => {
    setLoading(true);
    if (relatedTasks && calenderData) {
      setTimeout(() => {
        initChartData();
        setLoading(false);
      }, 10);
    }
  }, [relatedTasks, calenderData]);

  return (
    <>
      <div style={{ width: '100%', position: 'relative' }}>
        <MeteorEchart chartRef={instance} option={{ ...option, echartTheme }} style={{ height: '100%' }} />
        {loading && <Loading style={{ position: 'absolute' }} />}
      </div>
    </>
  );
};
export default PathMpPeak;
